var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    paddingTop:
      _vm.$route.path == '/exchange' || _vm.$route.path == '/exchangeDetail'
        ? '20px'
        : '80px',
  }),attrs:{"id":"app"}},[(
      _vm.$route.path != '/404' &&
      _vm.$route.path != '/exchange' &&
      _vm.$route.path != '/exchangeDetail'
    )?_c('nav-header'):_vm._e(),(_vm.isRouterAlive)?_c('router-view'):_vm._e(),(
      _vm.$route.path != '/404' &&
      _vm.$route.path != '/exchange' &&
      _vm.$route.path != '/exchangeDetail'
    )?_c('nav-footer'):_vm._e(),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float-box"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://line.me/R/ti/p/@esimart?from=page&searchId=esimart","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/image/line-right.png"),"alt":"line"}})])])])])
}]

export { render, staticRenderFns }